<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <!--template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template-->
      <v-card v-if="editedItem">
        <v-card-title>
          <span class="text-h5">
            {{$t('sidebar.resources', locale)}}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                style="padding: 0 20px;"
              >
                <translate
                  v-model="editedItem.Name"
                  :languages="languages"
                  :label="$t('booking.resources.name', locale)"
                  type="text"
                />
              </v-col>
              <!--v-col
                cols="12"
                sm="4"
                md="4"
              >
                <v-text-field
                  label="Limit"
                  required
                  v-model="editedItem.LimitResource"
                />
              </v-col-->
              <v-col
                v-if="isSeatMap"
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  :items="seatMapImages"
                  v-model="editedItem.SeatMapImage"
                  :label="$t('booking.resources.seatMapImage', locale)"
                  itemText="name"
                  itemValue="id"
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-if="!editedItem.isNew"
            color="red"
            @click="handleDelete"
          >
            {{ $t('common.delete', locale) }}
          </v-btn>
          <v-btn
            @click="onClose"
          >
            {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Translate from '@/components/Translate'
export default {
  components: {
    Translate,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    showForm: {
      type: Boolean,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    isSeatMap: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,
  }),
  computed: {
    languages () {
      return this.$store.state.app.bookingLanguages
    },
    seatMapImages () {
      return [{
        id: null,
        name: '',
      }, {
        id: 'https://cm-booking-api.dview.es/assets/resourceIcons/camaBalinesa200x200.png',
        name: 'Cama balinesa',
      },
      ]
    },
  },
  watch: {
    editedID () {
      this.prepareData()
    },
    showForm () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.dialog = this.showForm
      if (!this.dialog) {
        this.editedItem = null
      } else {
        this.editedItem = JSON.parse(JSON.stringify(this.item))
      }
    },
    handleSave () {
      this.onChange(this.editedItem)
    },
    handleDelete() {
      this.onDelete(this.editedItem.ID)
    },
  },
}
</script>


<template>
  <div>
      <v-card
        elevation="0"
      >
        <v-card-text class="card-text">
          <item-form
            :showForm="showForm"
            :item="editedItem"
            :onChange="handleChangeResourceItem"
            :onDelete="handleDelete"
            :onClose="handleClose"
            :locale="locale"
            :isSeatMap="isSeatMap"
          />
          <custom-table
            :loading="loading"
            :title="$t('sidebar.resources', locale)"
            :headers="[
              { text: $t('booking.resources.name', locale), value: 'tIdent' },
            ]"
            :onGetData="handleGetResourceItems"
            :tableData="resourceItems"
            :onEditItem="handleEdit"
            :onDeleteItem="handleDelete"
            :startIndex="0"
            :itemsPerPage="1000"
            :count="count"
          />
        </v-card-text>
        <v-card-text class="card-text">
          <seats-map 
            v-if="isSeatMap"
            :editedID="resourcesID"
            :resourceItems="resourceItems"
            :locale="locale"
            :seatMapBackground="seatMapBackground"
            :onChange="handleChangeSeatsMap"
            :onChangeSeatMapBackground="onChangeSeatMapBackground"
            :onDeleteSeatMap="onDeleteSeatMap"
          />
        </v-card-text>
      </v-card>
  </div>
</template>
<script>
const defaultItem = {
  Name: { es: '' },
  LimitResource: 1,
  isNew: true,
}
import api from '@/services/api'
import utils from '@/services/utils'
import CustomTable from '@/components/customTable/Index'
import ItemForm from './ItemForm'
import SeatsMap from './seatsMap/Index'
export default {
  components: {
    CustomTable,
    ItemForm,
    SeatsMap,
  },
  props: {
    resourcesID: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    seatMapBackground: {
      type: String,
      default: null,
    },
    onChangeSeatMapBackground: {
      type: Function,
      required: true,
    },
    onDeleteSeatMap: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    isSeatMap: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    resourceItems: [],
    count: 0,
    editedItem: null,
    showForm: false,
    loading: false,
  }),
  watch: {
    resourcesID () {
      this.handleGetResourceItems()
    },
  },
  mounted () {
    this.handleGetResourceItems()
  },
  methods: {
    handleGetResourceItems () {
      if (!this.resourcesID) return
      this.loading = true
      api.getAllWithoutLimit ('booking', `v1/private/resources/${this.resourcesID}/resource-items`)
        .then(response => {
          if(!response) return
          this.count = response.length
          this.resourceItems = response.map(item => {
            item.Name = JSON.parse(item.Name)
            return item
          })
          this.loading = false
        })
    },
    handleClose () {
      this.editedItem = null
      this.showForm = false
    },
    handleEdit (v) {
      if (!v) {
        const aux = JSON.parse(JSON.stringify(defaultItem))
        aux.ID = utils.getNewID()
        this.editedItem = aux
      } else {
        const aux = this.resourceItems.filter(x => x.ID === v)
        this.editedItem = aux.shift()
        if (!this.editedItem) return
      }
      this.showForm = true
    },
    handleDelete (v) {
      this.resourceItems = this.resourceItems.filter(x => x.ID !== v)
      this.onChange(this.resourceItems)
      this.editedItem = null
      this.showForm = false
    },
    handleChangeResourceItem (v) {
      this.loading = true
      v.isNew = false
      if (this.resourceItems.filter(x => x.ID === v.ID).length > 0) {
        this.resourceItems = this.resourceItems.map(item => {
          if (item.ID === v.ID) item = JSON.parse(JSON.stringify(v))
          return item
        })
      } else this.resourceItems.push(JSON.parse(JSON.stringify(v)))
      this.editedItem = null
      this.showForm = false
      this.loading = false
      this.onChange(this.resourceItems)
    },
    handleChangeSeatsMap (v) {
      for (const item of this.resourceItems){
        const aux = v.filter(x => x.id===item.ID).shift()
        if (aux) {
          item.Width = aux.width
          item.Height = aux.height
          item.Top = aux.top
          item.Left = aux.left
          item.ScaleX = aux.scaleX
          item.ScaleY = aux.scaleY
          item.Angle = aux.angle
        }
      }
      this.onChange(this.resourceItems)
    },
  },
}
</script>

